import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/handbook/post-layout/post-layout-secondary.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "overview",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#overview",
        "aria-label": "overview permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Overview`}</h2>
    <p>{`The International Baccalaureate (IB) Diploma programme is a challenging two-year curriculum, designed for students in Grades 11 and 12 (aged 16 – 19). It leads to a qualification widely recognised by the world’s leading universities. Students learn more than a collection of facts. The DP prepares students for university by encouraging them to ask challenging questions, to learn how to learn, to develop a strong sense of their own identity and culture, and to communicate with and understand people from other countries and cultures. Those who complete this programme enjoy a competitive edge in admission to colleges and universities around the world because of the following reasons:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`The IB Diploma curriculum is an integrated, comprehensive, and balanced mix of sciences, maths, humanities and arts.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The IB Diploma Programme provides an opportunity to study some subjects in-depth while covering others more broadly.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Students are evaluated according to the highest international standards of assessment over a two-year period.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Students receive explicit instruction in critical thinking and learn the interrelationship of academic subjects.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Students develop a respect for cultural diversity and gain a broader world view.`}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "qualifying-to-enter-the-ib-diploma-programme",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#qualifying-to-enter-the-ib-diploma-programme",
        "aria-label": "qualifying to enter the ib diploma programme permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Qualifying To Enter The Ib Diploma Programme`}</h2>
    <h3 {...{
      "id": "students-in-grade-10-must-meet-the-following-criteria-for-entry-into-the-dp",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#students-in-grade-10-must-meet-the-following-criteria-for-entry-into-the-dp",
        "aria-label": "students in grade 10 must meet the following criteria for entry into the dp permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Students in Grade 10 must meet the following criteria for entry into the DP:`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`At least 26 points in the six subject groups outlined below`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`At least 15 points (with a minimum of 5 points per subject) in the three subjects of their choice for HL classes`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Successful completion of the MYP Personal Project (This may be waived for students coming into Grade 11 from a non-MYP school; letters of reference from the sending school will indicate the student’s ability to work independently.)`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`Students who do not meet these criteria by June of their Grade 10 year will be registered for either the PSI Diploma or the PSI Diploma plus DP Certificates. Some students may be allowed to enter the full DP on a probationary basis, with the approval of the Secondary principal and the DP coordinator. Those students will either be removed from probation at the end of their first semester in the DP or will be removed from the full DP.`}</strong></p>
    <h2 {...{
      "id": "curriculum",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#curriculum",
        "aria-label": "curriculum permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Curriculum`}</h2>
    <p>{`The DP is a comprehensive programme that requires students to demonstrate knowledge`}</p>
    <p>{`and skills through internal and external assessments in the following six interrelated academic areas:`}</p>
    <table>
    <colgroup>
        <col style={{
          "width": "48%"
        }} />
        <col style={{
          "width": "51%"
        }} />
    </colgroup>
    <thead>
    <tr className="header">
        <th><p>DP CURRICULUM GROUP</p></th>
        <th><p>PSI COURSE OFFERINGS</p></th>
    </tr>
    </thead>
    <tr className="odd">
        <td><p>Studies in Language and Literature: A mother-tongue language, used for a study of literature</p></td>
        <td><p>English, French, Russian or School Supported Self-Taught in the student’s language of choice*+</p></td>
    </tr>
    <tr className="header">
        <td><p>Language Acquisition: A second modern language taught at different levels of competency</p></td>
        <td><p>English, French, Russian, Spanish, Russian ab initio*, Spanish ab initio*, French ab initio*</p></td>
    </tr>
    <tr className="odd">
        <td><p>Individuals and Societies</p></td>
        <td><p>History, Geography, Economics, Global Politics, Psychology#, Business Management</p></td>
    </tr>
    <tr className="header">
        <td><p>Experimental Sciences</p></td>
        <td><p>Environmental Systems and Societies*, Design Technology, Biology, Chemistry, Physics</p></td>
    </tr>
    <tr className="odd">
        <td><p>Mathematics</p></td>
        <td>Applications and Interpretation SL, Analysis and Approaches SL, Analysis and Approaches HL</td>
    </tr>
    <tr className="header">
        <td><p>Arts**</p></td>
        <td><p>Visual Arts, Theatre, Music</p></td>
    </tr>
    <tr className="odd">
        <td><p>Other DP Requirements</p></td>
        <td><p>Theory of Knowledge</p>
            <p>Creativity, Activity, Service (CAS)</p>
            <p>Extended Essay</p></td>
    </tr>
    </table>
    <p><strong parentName="p">{`These courses are offered at Standard Level only.`}</strong></p>
    <p>{`For School Supported Self-Taught courses, the School will pay the tutor but may seek help from the parents in locating an appropriate person. The school will maintain contact with the tutor to ensure that s/he is following the programme.`}</p>
    <p>{`Students may opt to take a course from Groups 1 - 4 instead of an Arts offering.`}</p>
    <p>{`Psychology is offered through an IB-recognised online programme. Only students with a demonstrated ability to work independently may apply. Those students are monitored by the Distance Learning coordinator.`}</p>
    <h2 {...{
      "id": "ib-diploma-requirements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#ib-diploma-requirements",
        "aria-label": "ib diploma requirements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IB Diploma Requirements`}</h2>
    <p>{`As outlined on page 10, students may choose one of three tracks in Grades 11 and 12: The PSI High School Diploma, the PSI Diploma plus DP Courses or the PSI Diploma plus the IB Diploma. The information that follows focuses only on the third track and outlines requirements for completing the IB Diploma.`}</p>
    <h2 {...{
      "id": "higher-level--hl-and-standard-level--sl",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#higher-level--hl-and-standard-level--sl",
        "aria-label": "higher level  hl and standard level  sl permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Higher Level ( Hl) And Standard Level ( Sl)`}</h2>
    <p>{`Students who wish to achieve the full IB Diploma must take one course offering from each of the groups indicated on the chart above. Three of those must be at Standard Level (SL) and three must be at Higher Level (HL).`}</p>
    <p>{`SL courses meet the equivalent of four 40-minute periods per week over the two years. HL courses meet the equivalent of six 40-minute periods per week. In general, PSI offers HL and SL courses in the same class; teachers dismiss SL students as needed. Those students use that time as a study period. (The exception is Mathematics, where the course outlines for Application and Interpretations (SL only) and Analysis and Approaches (HL and SL ) are vastly different.)`}</p>
    <p>{`Each subject group has different requirements for HL students. In some cases, HL courses offer additional units and more topics. In other subject areas, HL students study the same units as SL candidates, but do so at greater depth. HL students also complete additional assessment and exam papers.`}</p>
    <h2 {...{
      "id": "other-requirements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#other-requirements",
        "aria-label": "other requirements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Other Requirements`}</h2>
    <p>{`In addition to coursework, candidates for the full IB Diploma take a course called Theory of Knowledge (TOK), where they learn to analyse the methods and biases of the various disciplines of study. TOK helps students to understand the nature of knowledge and the strengths and weaknesses of knowledge claims made in each of the subject areas. TOK gives students an insight into their programme of study that inevitably strengthens their ability to specialise at college or university in an informed way. Students also complete a TOK essay that is submitted for external marking.`}</p>
    <p>{`Second, IB Diploma candidates complete an Extended Essay, an original research paper of approximately 4,000 words. Although every student works with a teacher-mentor who helps guide his / her work, the Extended Essay is designed to be an independent project and is excellent preparation for future studies at university level. The Extended Essay is also externally marked by the IB.`}</p>
    <p>{`Finally, Diploma candidates participate in CAS (Creativity, Activity, Service). Throughout Grades 11 and 12, students complete a wide variety of projects in each of the three areas of this programme. Through their involvement in non‐academic activities, students develop important life skills, and demonstrate how well-rounded and interesting they are. These are attributes that university admissions officers seek in applicants. Students must document their work, write reflections and submit a portfolio, which is sent to the IB for external moderation.`}</p>
    <h2 {...{
      "id": "ib-diploma-assessment",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#ib-diploma-assessment",
        "aria-label": "ib diploma assessment permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IB Diploma Assessment`}</h2>
    <p>{`The IB assesses all objectives for each subject through a range of tasks that focus on higher order thinking skills of synthesis, reflection, evaluation and critical thinking, as well as the more fundamental skills of knowledge, understanding and application.`}</p>
    <p><strong parentName="p">{`Types of Assessments`}</strong></p>
    <p>{`IB Diploma and DP Course candidates are assessed in three ways: internally, externally, and by exam.`}</p>
    <p>{`Internal assessments are set and marked by classroom teachers in accordance with IB guidelines. An external IB moderator then reviews a sample set of internal assessments and provides the school with feedback that ensures that work set by teachers is appropriately challenging and correctly assessing students.`}</p>
    <p>{`External assessments are set and corrected by the IB. Subject examiners mark these papers and projects and assign a grade based on the international standards of the IB.`}</p>
    <p>{`Exams are held for Grade 12 students each May in each of the first five subject areas. These exams may use a variety of techniques, including essays, short answer questions and multiple-choice objective tests. Exams are prepared and standardised by examining panels responsible for each subject. External examiners use an agreed-upon mark scheme for all students worldwide who take that exam. In lue of exams students who take Visual Arts or Theatre submit samples of work that are internally and externally viewed and assessed.`}</p>
    <h2 {...{
      "id": "earning-the-ib-diploma",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#earning-the-ib-diploma",
        "aria-label": "earning the ib diploma permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Earning The Ib Diploma`}</h2>
    <p>{`Performance on exams is added to the marks awarded on internal and external assessments. The IB then assigns a grade from 1-7 for each subject.`}</p>
    <p>{`Diploma candidates can earn up to 45-points overall: 7 points for each of their six subjects, plus 3 additional points for achievement in TOK and Extended Essay. Although students do not receive points for their CAS work, students cannot receive their IB Diploma unless they submit a satisfactory CAS portfolio to the school’s CAS coordinator.`}</p>
    <p>{`In general, students must earn 24 or more points overall in order to receive the IB Diploma. An additional stipulation is that students must score a minimum of 12 points overall from their three Higher Level classes. In order to ensure that students are ready for the full exam schedule, PSI asks that all DP students maintain an overall grade of 26 points for their course work. Students who fall below that are placed on probation by the school and may be at risk of not being enrolled as full Diploma students.`}</p>
    <h2 {...{
      "id": "reviewing-the-results-of-ib-marks",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#reviewing-the-results-of-ib-marks",
        "aria-label": "reviewing the results of ib marks permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Reviewing The Results Of Ib Marks`}</h2>
    <p><strong parentName="p">{`IB Examination Results`}</strong></p>
    <p>{`IB Diploma and Certificate students access their IB examination results in July through the IB website, using a PIN code provided by the school’s DP coordinator. The IB does not release results to students or their families by other means. All communication concerning results must be made through the DP coordinator at PSI.`}</p>
    <p><strong parentName="p">{`Enquiry Upon Results (EUR)`}</strong></p>
    <p>{`A student who feels that his / her result does not reflect his / her performance may appeal through a process known as Enquiry Upon Results (EUR). This can include a clerical check of the marking procedure or a reassessment of the exam by another examiner. Students who wish to apply for the EUR must first consult the school’s DP coordinator and pay any additional fees before the 15th September deadline.`}</p>
    <p><strong parentName="p">{`Retaking Examinations`}</strong></p>
    <p>{`If an IB Diploma or Certificate track student wishes to improve upon one or more subject grades, s/he may retake the subject exam(s).`}</p>
    <p>{`PSI offers November retakes only for students who have not passed the IB Diploma. The deadline to enrol for the November retake is usually 29th July.`}</p>
    <p>{`Any student may request a retake during the May exams. That deadline will be posted by the IB each year.`}</p>
    <p>{`No requests after the deadline will be accepted by the IB. All requests for either session must go through the PSI DP coordinator, who will provide information about the exam schedule, the fees for each exam, and other pertinent details, and will enrol students for the retakes. PSI and IB do not guarantee results of retakes. Students may go up, down or stay the same.`}</p>
    <h2 {...{
      "id": "expectations-and-consequences",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#expectations-and-consequences",
        "aria-label": "expectations and consequences permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Expectations And Consequences`}</h2>
    <p>{`In addition to academic requirements, both IB and PSI expect that students will behave in ethical and respectful ways. Students must realise that their personal behaviour determines the quality of their environment at PSI. They must be familiar with the guidelines for student conduct laid out in the PSI Parent and Student Handbook so that they are aware of the School’s expectations and of any consequences for misbehaviour.`}</p>
    <p>{`Students should also be aware that their performance throughout their four years of high school (Grades 9 - 12) is the key to university admission. Universities look not only for grades but also for evidence of maturity and responsibility. It is not to a student’s advantage to have a student record with instances of inappropriate behaviour and subsequent consequences (e.g., suspensions, letters concerning academic malpractice, etc.).`}</p>
    <p><strong parentName="p">{`Attendance`}</strong></p>
    <p>{`Attendance is vital for success in the DP. It is each student’s responsibility to be present in class and to inform teachers when s/he will be away. It is also the student’s responsibility to make up missed work. A DP Diploma or Course candidate will be asked to make up time on a Saturday for every five missed days so that the School can ensure that s/he has met the minimum time requirement set by IB. In addition, the PSI attendance policy states that any student who misses more than 20 school days in an academic year may put his / her graduation at risk.`}</p>
    <p><strong parentName="p">{`Academic Integrity`}</strong></p>
    <p>{`All PSI students are required to act in a responsible and ethical manner. In particular, DP candidates must avoid any form of academic malpractice. The IB defines this as behaviour that results in the candidate or any other candidate gaining an unfair advantage in one or more assessment components. Malpractice includes:`}</p>
    <p>{`a. Plagiarism: Representing the ideas or work of another person as the student’s own`}</p>
    <p>{`b. Collusion: Supporting malpractice by another student, as in allowing one’s work to be copied or submitted for assessment by another`}</p>
    <p>{`c. Duplication of work: The presentation of the same work for different assessment components and / or Diploma requirements`}</p>
    <p>{`d. Any other behaviour that gains an unfair advantage for a student or that affects the results of another candidate (for example, taking unauthorised material into an exam room, misconduct during an exam, falsifying a CAS record, disclosure or receipt of confidential information about exams).`}</p>
    <p>{`The IB does not award grades in subjects where malpractice has occurred. The IB will not award a Diploma to the candidate, but will award Certificates in other subjects where no malpractice has occurred. Students who engage in academic malpractice at PSI receive no grade for the work in question. A second incident in any class will result in students being withdrawn from the IB Diploma Programme as a full candidate. Further incidents may also`}</p>
    <p>{`result in failure of the course, suspension or expulsion. A record of malpractice can jeopardise university admission.`}</p>
    <p>{`The PSI Parent and Student Handbook has a full outline of the supports and sanctions for academic malpractice`}</p>
    <h2 {...{
      "id": "university-entrance-qualifications",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#university-entrance-qualifications",
        "aria-label": "university entrance qualifications permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`University Entrance Qualifications`}</h2>
    <p>{`IB qualifications are accepted worldwide for admission to university or college. Applications from students who graduate from IB World Schools are recognised as candidates who have undergone a rigorous and challenging curriculum. In many countries, the IB Diploma can replace the national entry qualifications for entry into university. In fact, many American universities grant the IB Diploma holder waivers or credits for courses based on the student’s final IB grades. In the United Kingdom, the IB Diploma is recognised as an entry qualification for all universities. In both countries, there are a number of scholarships and bursaries available specifically for university and college students who are IB Diploma graduates. In addition, Australia, Canada, Germany, the Netherlands, Spain and South Korea, to name a few, all recognise and appreciate the rigorous coursework and excellent education provided by the IB Diploma Programme. Each university and country has specific entry requirements for their programmes. Rest assured that earning the IB Diploma will ease PSI graduates’ entry into universities around the world.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      